import * as styles from '../styles/pages/nccn.module.css';

import React from 'react';
import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import References from '../components/References/References';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import Footnotes from '../components/Footnotes/Footnotes';
import { Column, TwoColumns } from '../components/TwoColumns/TwoColumns';
import CardEfficacy from '../components/CardEfficacy/CardEfficacy';
import JumpLink from '../components/JumpLink/JumpLink';

import SavingsIcon from '../images/savingsIcon.inline.svg';


import { GtagHead } from '../components/GTAG';
export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Efficacy NCCN guidelines | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description'
        content=''/>
    </>
  )
}

export default function NCCN() {

  const ctaDescriptionListData = [
    {
      cta: {
        text: <>LOQTORZI<sup>®</sup> at a glance</>,
        url:'/loqtorzi-at-a-glance/'
      },
      text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
        to help you <br className='desktop:hidden' />support your patients.</div>
    }, {
      cta: {
        text: <span>Interactive full<br/>
          Prescribing Information </span>,
        url:'/loqtorzi-prescribing-information/'
      },
      text: <div>Explore the details of JUPITER-02<br className='desktop:hidden' /> trial data in the interactive<br className='desktop:hidden' /> full Prescribing Information.</div>
    },
    {
      cta: {
        text: 'Get Updates',
        url: '/get-updates/'
      },
      text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
    }
  ];

  return (
    <Layout>
      <Title pageName={'Efficacy'}>
        NCCN CLINICAL <br className='tablet:hidden' />
        PRACTICE <br className='tablet:hidden' />
        GUIDELINES <br className='tablet:hidden' />
        IN ONCOLOGY <br className='tablet:hidden' />
        (NCCN GUIDELINES<sup>®</sup>)
      </Title>

      <DynamicContent divider={false}>
        <div className='wrapperContent !pb-0'>
          <div className='paragraph !mb-0'>
            <TwoColumns className='gap-[1.6rem] !pb-0'>
              <Column className='first:!border-0'>
                <CardEfficacy className='mb-[6rem] tablet:min-h-[24.5rem] desktop:min-h-[21rem]' content={{ icon: SavingsIcon,title: <h3>Category 1 <br className='desktop:hidden'/>Preferred</h3>}}>
                  <div className='font-MontserratRegular text-[1.6rem] leading-[2.1rem] text-left mt-[3.5rem] large:mt-0 pl-[.5rem]'>
                    Toripalimab-tpzi (LOQTORZI<sup>®</sup>) is the <strong>only NCCN Category 1-preferred first-line immunotherapy option</strong>, in combination with cisplatin and gemcitabine, for patients with recurrent, unresectable, or metastatic nasopharyngeal cancers (with no surgery or radiation therapy option).<sup className='font-MontserratRegular text-[0.8rem] text-left pt-0 leading-[2.1rem] top-[-0.7rem]'>2</sup>*<sup className='font-MontserratRegular text-[0.8rem] text-left pt-0 leading-[2.1rem] top-[-0.7rem]'>†</sup>
                  </div>
                </CardEfficacy>
              </Column>
              <Column>
                <CardEfficacy className='tablet:min-h-[24.5rem] desktop:min-h-[21rem]' content={{ icon: SavingsIcon,title: <h3>Category 2A <br className='desktop:hidden'/>Preferred</h3>}}>
                  <div className='font-MontserratRegular text-[1.6rem] leading-[2.1rem] text-left mt-[3.5rem] large:mt-0 pl-[.5rem]'>Toripalimab-tpzi (LOQTORZI<sup>®</sup>) is the <strong>only NCCN Category 2A-preferred subsequent-line immunotherapy option</strong> for patients with recurrent, unresectable, or metastatic nasopharyngeal cancers (with no surgery or radiation therapy option) with disease progression on or after platinum-containing therapy.<sup className='font-MontserratRegular text-[0.8rem] text-left pt-0 leading-[2.1rem] top-[-0.7rem]'>2†‡</sup></div>
                </CardEfficacy>
              </Column>
            </TwoColumns>
          </div>
        </div>

        <div className='wrapperContent'>
         <JumpLink className='!pl-0 mt-[3rem]' border={false} wrapperClassName={ styles.jump} content={{title:'See the data', link:'/loqtorzi-safety-first-line-treatment/', class:false}} />
          <Footnotes className={'mt-[2.17rem]'} items={[{
            bullet:'',
            text: <span>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application, and disclaims any responsibility for their application or use in any way. See the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) for detailed recommendations.</span>
          }]} />
          <Footnotes className={'mt-[1rem]'} items={[
              {
              bullet:'*',
              text: <span>Category 1: Based upon high-level evidence, there is uniform NCCN consensus that the intervention is appropriate.<sup>2</sup></span>
              },
              {
              bullet:<sup>†</sup>,
              text: <span>Preferred intervention: interventions that are based on superior efficacy, safety, and evidence; and, when appropriate, affordability.<sup>2</sup></span>
              },
              {
                bullet:<sup>‡</sup>,
                text: <span>Category 2A: Based upon lower-level evidence, there is uniform NCCN consensus that the intervention is appropriate.<sup>2</sup></span>
                },
                {
                  bullet:'',
                  text: <span>NCCN=National Comprehensive Cancer Network<sup>®</sup> (NCCN<sup>®</sup>).</span>
                  },
            ]} />
        </div>
      </DynamicContent>

      <References className={'!mt-0 !pt-0'} items={[
          <span>LOQTORZI<sup>®</sup> (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc.</span>,
          <span>Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) for Head and Neck Cancers V.3.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed March 3, 2024. To view the most recent and complete version of the guideline, go online to NCCN.org.</span>
        ]} border={true}></References>

      <CtaDescList content={ctaDescriptionListData} />

    </Layout>
  );
}