import React from 'react';
import * as styles from './CardEfficacy.module.css';
import clsx from 'clsx';

import StarWhite from '../../images/starWhite.inline.svg';

export default function CardEfficacy({ className, content, children }) {
  return (
    <div className={clsx(styles.cardWrapper, className )}>

      <div className={ clsx(styles.card)}>
        <div className={ styles.header }>
          <div><StarWhite /></div>
          <div className={styles.title}>
            { content.title}
          </div>
        </div>
      </div>
      { children }
    </div>
  );
}